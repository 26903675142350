import { useEffect, useCallback, useState } from 'react';
import { get, post, patch, deleteData } from 'utils/axiosRequests';
import { globalAction, dispatch } from 'store';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
// ==============================|| CONFIG - LOCAL STORAGE ||============================== //

export default function useListing(url) {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const pageNumber = searchParams.get('page');
    const {
        total,
        currentPage,
        recordPerPage,
        searchStr,
        startDate,
        endDate,
        roleId,
        materialId,
        assetId,
        siteId,
        itemCategoryId,
        outOfStock,
        listingData,
        order,
        orderBy,
        currentData
    } = useSelector((state) => state.listing);

    const [isLoading, setIsLoading] = useState(false);
    const [stateFilter, setStateFilter] = useState([]);
    const getListing = async () => {
        setIsLoading(true); // Loading Start
        const params = { page: currentPage, order, orderBy };
        if (searchStr) params.search = searchStr;
        if (startDate) params.startDate = startDate;
        if (endDate) params.endDate = endDate;
        if (roleId) params.roleId = roleId;
        if (materialId) params.materialId = materialId;
        if (assetId) params.assetId = assetId;
        if (siteId) params.siteId = siteId;
        if (itemCategoryId) params.itemCategoryId = itemCategoryId;
        if (outOfStock) params.outOfStock = outOfStock;
        if (stateFilter.length > 0) {
            params.filter = JSON.stringify(stateFilter);
        }
        params.limit = recordPerPage;
        await get(url, params)
            .then((response) => {
                setIsLoading(false); // Loading End
                dispatch(
                    globalAction('SET_LISTING', {
                        listingData: response.data,
                        total: response.total,
                        currentPage
                    })
                );
            })
            .catch((err) => console.log(err));
    };

    const setCurrentData = (currentData) => {
        dispatch(globalAction('SET_CURRENT_DATA', { currentData }));
    };

    useEffect(async () => {
        setCurrentData(undefined);
        await getListing();

        return () => {
            console.log('UnMount');
        };
    }, [
        currentPage,
        searchStr,
        order,
        orderBy,
        url,
        recordPerPage,
        stateFilter,
        startDate,
        endDate,
        roleId,
        materialId,
        assetId,
        siteId,
        itemCategoryId,
        outOfStock
    ]);
    useEffect(() => {
        if (pageNumber) {
            dispatch(globalAction('SET_CURRENT_PAGE', { currentPage: pageNumber }));
        } else {
            dispatch(globalAction('SET_CURRENT_PAGE', { currentPage: 1 }));
        }
    }, [pageNumber]);
    const setpage = (event, page) => {
        navigate(`?page=${page + 1}`);
        dispatch(globalAction('SET_CURRENT_PAGE', { currentPage: page + 1 }));
    };

    const setSearchStr = (str) => {
        navigate(`?page=${1}`);
        dispatch(globalAction('SET_CURRENT_PAGE', { currentPage: 1 }));
        dispatch(globalAction('SET_SEARCH_STR', { searchStr: str }));
    };
    const setStartDate = (str) => {
        navigate('?page=1');
        dispatch(globalAction('SET_CURRENT_PAGE', { currentPage: 1 }));
        dispatch(globalAction('SET_START_DATE', { startDate: str }));
    };
    const setEndDate = (str) => {
        navigate('?page=1');
        dispatch(globalAction('SET_CURRENT_PAGE', { currentPage: 1 }));
        dispatch(globalAction('SET_END_DATE', { endDate: str }));
    };
    const setRoleId = (str) => {
        navigate('?page=1');
        dispatch(globalAction('SET_CURRENT_PAGE', { currentPage: 1 }));
        dispatch(globalAction('SET_ROLE_ID', { roleId: str }));
    };
    const setMaterialId = (str) => {
        navigate('?page=1');
        dispatch(globalAction('SET_CURRENT_PAGE', { currentPage: 1 }));
        dispatch(globalAction('SET_MATERIAL_ID', { materialId: str }));
    };
    const setAssetId = (str) => {
        navigate('?page=1');
        dispatch(globalAction('SET_CURRENT_PAGE', { currentPage: 1 }));
        dispatch(globalAction('SET_ASSET_ID', { assetId: str }));
    };
    const setSiteId = (str) => {
        navigate('?page=1');
        dispatch(globalAction('SET_CURRENT_PAGE', { currentPage: 1 }));
        dispatch(globalAction('SET_SITE_ID', { siteId: str }));
    };
    const setItemCategoryId = (str) => {
        navigate('?page=1');
        dispatch(globalAction('SET_CURRENT_PAGE', { currentPage: 1 }));
        dispatch(globalAction('SET_ITEM_CATEGORY_ID', { itemCategoryId: str }));
    };
    const setOutOfStock = (str) => {
        navigate('?page=1');
        dispatch(globalAction('SET_CURRENT_PAGE', { currentPage: 1 }));
        dispatch(globalAction('SET_OUT_OF_STOCK', { outOfStock: str }));
    };
    const updateStatus = async (id, status) => {
        if (window.confirm(`Are you sure to ${status}`)) {
            patch(`${url}/${id}`, {
                status
            }).then((res) => {
                if (res.status === 'success') {
                    getListing();
                    // const dataIndex = listingData.findIndex((data) => data.id === id);

                    // listingData[dataIndex].status = status;
                    // const tempListingData = [...listingData];
                    // console.log(tempListingData);
                    // dispatch(globalAction('UPDATE_LISTING', { listingData: tempListingData }));
                }
            });
        }
    };
    const deleteDatabyId = async (id) => {
        if (window.confirm(`Are you sure to Delete ?`)) {
            deleteData(`${url}/${id}`)
                .then((res) => {
                    if (res.status === 'success') {
                        getListing();
                    }
                })
                .catch((err) => console.log(err, 'Error'));
        }
    };

    const changeStatus = (event, id) => {
        const status = event.target.checked ? 'Active' : 'Inactive';
        updateStatus(id, status);
    };

    const deleteRow = (e, id) => {
        // updateStatus(id, 'Deleted');
        deleteDatabyId(id);
    };

    const changeOrder = useCallback((columnName) => {
        dispatch(globalAction('CHANGE_ORDER', { orderBy: columnName }));
    }, []);

    const resetListing = () => {
        dispatch(globalAction('RESET_LISTING'));
    };

    const rowsPerPage = (row) => {
        dispatch(globalAction('CHANGE_RECORD_PER_PAGE', { recordPerPage: row.target.value }));
    };

    return {
        total,
        currentPage,
        listingData,
        searchStr,
        recordPerPage,
        order,
        orderBy,
        isLoading,
        stateFilter,
        setpage,
        setSearchStr,
        setStartDate,
        setEndDate,
        setRoleId,
        setMaterialId,
        setAssetId,
        setSiteId,
        setItemCategoryId,
        setOutOfStock,
        changeStatus,
        deleteRow,
        setCurrentData,
        changeOrder,
        resetListing,
        rowsPerPage,
        getListing,
        setStateFilter
    };
}
